// import { ROOT_DOMAIN } from '@/constants';
// export default function imageLoader({ src, width, quality }) {
//     const protocol = process.env.NODE_ENV === 'production' ? 'https://www.' : 'http://';
//     const params = [`width=${width}`, `quality=${quality || 75}`, 'format=auto'];

//     const shouldUseNextImage =
//     src.startsWith('http') || // Check if the source is external
//     src.includes('supabase.co') || // Check if the source is from Supabase Storage
//     src.includes('cdn.sanity.io') || // Check if the source is from Sanity
//     src.includes('/img/perks/') || // Check if the source is a Perks image
//     src.includes('fbcdn.net'); // Check if the source is from Facebook CDN
//     src.includes('cdninstagram.com'); // Check if the source is from Instagram CDN
//     console.log(`${protocol}${ROOT_DOMAIN}/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`)
//     return src
//     if (shouldUseNextImage) {
//         return `${protocol}${ROOT_DOMAIN}/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality || 75}`;
//         // return `https://www.upgrow.com/cdn-cgi/image/${params.join(',')}/${src}`;
//     } else {
//         // Docs: https://developers.cloudflare.com/images/url-format
//         return `https://www.upgrow.com/cdn-cgi/image/${params.join(',')}/${src}`;
//     }
// }

export const cloudflareImageLoader = ({ src, width, quality }) => {
  const params = [`width=${width}`, `quality=${quality || 75}`, 'format=auto'];
  // Remove leading slash if present
  if (src.startsWith('/')) {
    src = src.slice(1);
  }
  return `https://www.upgrow.com/cdn-cgi/image/${params.join(',')}/${src}`;
};
