import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import clsx from 'clsx';
import { Instagram, InstagramPostProps } from '@shared-libs/instagram';
import { useCountUp } from 'react-countup';
import Anchor from 'shared-ui/components/Anchor';
import { MdVerified } from 'react-icons/md';
import { HiOutlineArrowCircleRight, HiChevronDown } from 'react-icons/hi';
import ImageWithFallback from './ImageWithFallback';
import { LoadingSpinner } from './LoadingSpinner';
import { TbExternalLink } from 'react-icons/tb';
// import getInstagramProfile from '@/pages/api/instagram/get-profile';

// const instagram_profiles = [
//   {
//     "username": "travelandleisure",
//     "full_name": "Travel + Leisure",
//     "is_verified": true,
//     "profile_pic_url": "https://instagram.fcpt3-1.fna.fbcdn.net/v/t51.2885-19/s150x150/245379498_2672485919673103_527164285141118676_n.jpg?_nc_ht=instagram.fcpt3-1.fna.fbcdn.net&_nc_ohc=ZsVPryN8QwEAX-WjDLY&edm=ABfd0MgBAAAA&ccb=7-4&oh=7ce09d8ec103f10915d12122d1029a9a&oe=6215E5A5&_nc_sid=7bff83",
//     "follower_count": 3669033,
//     "following_count": 352,
//     "media_count": 9795,
//     "bio": "Your travel dreams, realized.",
//     "external_url": "https://www.travelandleisure.com/",
//     posts: [
//       {
//         url: 'https://instagram.com/p/Cour2ZPSxF5',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t39.30808-6/330192801_753931225889081_8185439586572312346_n.png?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=Q1BA-gzXDH4AX85CFBc&edm=AJfeSrwAAAAA&ccb=7-5&oh=00_AfB7VdqlikMAMcJEZ_QZ-cQoNYFVTDLsQe4yhVpMRYznjA&oe=63F459CA&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/CosvOZ7oLnr',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t51.2885-15/330866650_715229753483857_3419389977277982071_n.jpg?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=JDeoknziiHAAX-ZWta5&edm=AJfeSrwBAAAA&ccb=7-5&oh=00_AfB4czX853ae6ht8vNadN861e7ixRvnV7llDrhid1yjuWA&oe=63F3D6CA&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/CosvLSJsedz',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t39.30808-6/329811669_882375499683817_8662754183154343390_n.png?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=iMV-mNQjJOIAX8_FrfK&edm=AJfeSrwAAAAA&ccb=7-5&oh=00_AfDQFIALueDAw2i-cmsjKmh0c5ADpdnzH7mLXpwGKURyQg&oe=63F44602&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/CosFmu8LzN9',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t51.2885-15/330755843_566783955479103_6717888689940866856_n.jpg?stp=dst-jpg_e35_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=103&_nc_ohc=uRNR6vTZ9BcAX_-I6A_&edm=AJfeSrwBAAAA&ccb=7-5&oh=00_AfA9sS-F2mddvmIfPWk36wR3Y5WgrhCzWfpBbXycFJqbwA&oe=63F4829E&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/Coqw0v4sBYG',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t39.30808-6/329820482_749096200265197_7691798088120779237_n.png?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=QjiGtcp5HRcAX_VvcK5&edm=AJfeSrwAAAAA&ccb=7-5&oh=00_AfCmlBDXNob4yYF6KkMze3nGGiQawL4NSRvWGJUuOgntuA&oe=63F44AD8&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/Copu68ktKQ_',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t39.30808-6/331149533_749592156608377_6991830152428396126_n.png?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=mdoL6pxOfEAAX8zzw6-&edm=AJfeSrwAAAAA&ccb=7-5&oh=00_AfCd8wNmxmcUomZjt_ARjT9tTSuDouZxzV11yVlINR3Oww&oe=63F40C0C&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/CophR0fMwvH',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t51.2885-15/330968117_1385339862266512_461814262456031788_n.jpg?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=CmaM_J3uHcQAX9dIIfy&edm=AJfeSrwBAAAA&ccb=7-5&oh=00_AfBYS9vcH2zZvois5IflvYgUW9c0zYlA09-l4pLDsikxUA&oe=63F41EFA&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/Conll14JyPw',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t39.30808-6/328837510_530576025605170_3152991450914100162_n.png?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=oXfxzk3oLbAAX-U-dtl&edm=AJfeSrwAAAAA&ccb=7-5&oh=00_AfD2Rh2Zyv0w_AvgRa4w05A1edwqNhrL5JZn8e2y51A8fQ&oe=63F42376&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/Com8aWMtyE0',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t51.2885-15/328561195_860528111729124_7804899557973088670_n.jpg?stp=dst-jpg_e35_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=100&_nc_ohc=bTU1IDCUGK4AX__6J1A&edm=AJfeSrwBAAAA&ccb=7-5&oh=00_AfDXgz4xESUv8Kkr6RQkNaq8zrBNarbKSYG1x9eRmdNIuQ&oe=63F4BFF2&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/ColA4MdMDQn',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t51.2885-15/330477440_968597674122464_3395371253851712950_n.jpg?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=miMhZzIZiFIAX8l8ccA&edm=AJfeSrwBAAAA&ccb=7-5&oh=00_AfAlMtm7MckZPKSuzlw9kwW-0R48XqxOSw8GYEOryMrbFg&oe=63F48951&_nc_sid=588073',
//       },
//       {
//         url: 'https://instagram.com/p/CokXqtRoqCr',
//         image_url:
//           'https://instagram-flhr13--1-fna-fbcdn-net.translate.goog/v/t51.2885-15/330387629_907978713959721_2708583612601361847_n.jpg?stp=dst-jpg_e15_s320x320&_nc_ht=instagram.flhr13-1.fna.fbcdn.net&_nc_cat=109&_nc_ohc=gK-9U7SG59sAX_zA8Ib&edm=AJfeSrwBAAAA&ccb=7-5&oh=00_AfAQUDMOJMyYRxgL26E06nyilFX6PptKj7lfVQtV5zRD3g&oe=63F47A1B&_nc_sid=588073',
//       },
//     ],
//   },
//   {
//     "username": "biamichelle",
//     "full_name": "Beatriz Michelle",
//     "is_verified": true,
//     "profile_pic_url": "/img/uploads/biamichelle.jpg",
//     "follower_count": 340989,
//     "final_follower_count": 1046421,
//     "following_count": 1001,
//     "media_count": 790,
//     "bio": `Brazilian fitness model and personal trainer.
//     SP 📍
//     💅🏻 Bailarina do Faustão 🤍🩰`,
//     "external_url": "https://youtube.com/@biamichelle"
//   }
// ]

const demoInstagramProfile = {
  username: "biamichelle",
  full_name: "Beatriz Michelle",
  is_verified: true,
  profile_pic_url: "/img/uploads/biamichelle.jpg",
  follower_count: 340989,
  final_follower_count: 1546421,
  following_count: 1001,
  media_count: 790,
  bio: `Brazilian fitness model and personal trainer.
  SP 📍
  💅🏻 Bailarina do Faustão 🤍🩰`,
  "external_url": "https://youtube.com/@biamichelle"
}


export default function InstagramProfileCard({
  className,
  instagramProfile,
  onUsernameSubmit,
  onLoadedProfile,
  hideUsernameInput = false,
  showAfterFollowers = false,
  isSampleAccount = false,
  isTinderCard = false,
  isTinderCardVisible = false,
  includePosts = false
}: {
  className?: string;
  instagramProfile?: any;
  onUsernameSubmit?: (username: string) => void;
  onLoadedProfile?: (profile: any) => void;
  hideUsernameInput?: boolean;
  showAfterFollowers?: boolean;
  isSampleAccount?: boolean;
  isTinderCard?: boolean;
  isTinderCardVisible?: boolean;
  includePosts?: boolean;
}) {

  const pathName = usePathname();
  const isOnInstagramProfilePage = pathName.includes('/instagram/');
  const [isLoading, setIsLoading] = useState(false);
  const [enteredUsername, setEnteredUsername] = useState<string>('');
  const [selectedInstagramProfile, setSelectedInstagramProfile] =
    isTinderCard ? useState(instagramProfile) :
      instagramProfile ? [instagramProfile, (ig_profile) => {
        setIsCounterRunning(false);
        instagramProfile = ig_profile;
      }] : useState(instagramProfile ?? demoInstagramProfile);
  const [isCounterRunning, setIsCounterRunning] = useState(false);
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  useEffect(() => {
    setShouldAutoFocus(window.innerWidth > 768);
  }, []);

  useEffect(() => {
    if (isTinderCard && isTinderCardVisible && !instagramProfile.followerCount) {
      // Fetch the full Instagram profile (with posts) from API
      setIsLoading(true);
      fetch(`/api/instagram/get-profile?username=${instagramProfile.username}&includePosts=true&preferredStrategy=InstagramScraper2022`)
        .then(res => res.json())
        .then((data) => {
          console.log(data)
          if (data && data.graphql && data.graphql.user) {
            console.log(`NEW InstagramProfileCard: ${data.graphql.user.username}, ${data.graphql.user.follower_count}`)

            setIsLoading(false);
            const fetchedInstagramProfile = data.graphql.user;
            setSelectedInstagramProfile({
              username: fetchedInstagramProfile.username,
              full_name: fetchedInstagramProfile.full_name,
              is_verified: fetchedInstagramProfile.is_verified,
              profile_pic_url: (isTinderCard && instagramProfile.profile_pic_url) ? instagramProfile.profile_pic_url : fetchedInstagramProfile.profile_pic_url,
              follower_count: fetchedInstagramProfile.follower_count,
              following_count: fetchedInstagramProfile.following_count,
              media_count: fetchedInstagramProfile.media_count,
              bio: fetchedInstagramProfile.biography,
              external_url: fetchedInstagramProfile.external_url,
              posts: fetchedInstagramProfile.edge_owner_to_timeline_media ? fetchedInstagramProfile.edge_owner_to_timeline_media.map((post: InstagramPostProps) => ({
                id: post.id,
                image_url: post.image_url,
                comment_count: post.comment_count,
                like_count: post.like_count,
                taken_at: post.taken_at,
              })) : [],
            });
          }
        })
    }
  }, [isTinderCard, isTinderCardVisible, instagramProfile]);


  const CountUpHook = () => {
    const countUpRef = useRef(null);
    const { start, pauseResume, reset, update } = useCountUp({
      ref: countUpRef,
      start: selectedInstagramProfile.follower_count,
      end: selectedInstagramProfile.final_follower_count || selectedInstagramProfile.follower_count * 1.9,
      duration: 3,
      separator: ",",
      decimals: 0,
      delay: 0,
      useEasing: true,
      onReset: () => {
        // console.log('Resetted!')
      },
      onUpdate: () => console.log('Updated!'),
      onPauseResume: () => console.log('Paused or resumed!'),
      onStart: ({ pauseResume }) => {
        // setTimeout(() => {
        setIsCounterRunning(true);
        // }, 2000);
        // console.log(pauseResume)
      },
      onEnd: ({ pauseResume }) => {
        // console.log(pauseResume)
      }
    });
    return (
      <>
        <span ref={countUpRef} className={clsx('mx-auto h-8 flex transform-gpu items-center justify-center rounded-lg text-center transition-all duration-500',
          isCounterRunning || showAfterFollowers ? 'mb-1 bg-green-500 py-1 px-2 text-white saturate-[1.2]' : ''
        )} />
        {/* <button onClick={start}>Start</button>
        <button onClick={reset}>Reset</button>
        <button onClick={pauseResume}>Pause/Resume</button>
        <button onClick={() => update(2000)}>Update to 2000</button> */}
      </>
    );
  };

  function onUsernameFormSubmit(e) {
    console.log('just submitted username component');
    if (e) e.preventDefault();
    // console.log(cardRef)
    // setErrorMessage(null); // Clear any previous errors
    if (onUsernameSubmit)
      return onUsernameSubmit(enteredUsername);

    setIsLoading(true);

    fetch(`/api/instagram/get-profile?username=${enteredUsername}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.graphql && data.graphql.user) {
          const fetchedInstagramProfile = data.graphql.user;
          setSelectedInstagramProfile({
            username: fetchedInstagramProfile.username,
            full_name: fetchedInstagramProfile.full_name,
            is_verified: fetchedInstagramProfile.is_verified,
            profile_pic_url: fetchedInstagramProfile.profile_pic_url,
            follower_count: fetchedInstagramProfile.follower_count,
            following_count: fetchedInstagramProfile.following_count,
            media_count: fetchedInstagramProfile.media_count,
            bio: fetchedInstagramProfile.biography,
            external_url: fetchedInstagramProfile.external_url,
            posts: [],
          });

          // Redirect to the account page using the router
          // router.push(`/app/account/${selectedAccount.data.id}?upgraded=true`);
          // onClose();
        } else if (data.error) {
          console.error(`OH NOOOOOO IG error: `);
          console.error(data.error);
          // setErrorMessage(data.error);
        }
        setIsLoading(false);
        setIsCounterRunning(false);
        onLoadedProfile(selectedInstagramProfile);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  return (
    <div className={clsx(className)}>
      <div className={clsx("relative w-full rounded-bl-none", !isTinderCard ? 'tinder--card' : '')}>

        {!hideUsernameInput && (
          <section className="top-bar">
            <form className="relative w-full"
              onSubmit={(event) => {
                event.preventDefault();
                onUsernameFormSubmit(event);
              }}>
              <input className="block w-full h-10 px-3 py-3 m-0 leading-5 text-center transition border border-none rounded-lg appearance-none text-md cursor-text border-neutral-500 bg-neutral-100 focus:shadow-lg focus:outline-4 focus:outline-offset-2 focus:ring-2 focus:ring-indigo-600 "
                type="text"
                id="instagram_username"
                autoFocus={shouldAutoFocus}
                required
                disabled={isLoading}
                placeholder="Enter your Instagram username..."
                onChange={(e) => {
                  setEnteredUsername(e.target.value);
                }}
              />
              <button className="absolute right-[12px] top-0 bottom-0 my-auto cursor-pointer text-gray-600" aria-label="Search Username">
                {!isLoading ? (
                  <HiOutlineArrowCircleRight className="text-lg" />
                ) : (
                  <svg className="w-5 h-5 mr-3 -ml-1 text-gray-600 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx={12} cy={12} r={10} stroke="currentColor" strokeWidth={4} />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                )}
              </button>
            </form>
          </section>
        )}

        {/* {JSON.stringify(selectedInstagramProfile)} */}

        {(isLoading && !isTinderCard) ? (
          <SkeletonInstagramProfile />
        ) : (
          selectedInstagramProfile &&
          selectedInstagramProfile.username &&
          selectedInstagramProfile.username.length > 0 && (
            <>
              <section className="top-bar">
                <span className="top-bar__back-btn" style={{ visibility: 'hidden', marginLeft: '30px' }} />
                <div className="top-bar__title">
                  {' '}
                  <span className="top-bar__title__text ">
                    {selectedInstagramProfile.username}
                  </span>
                  {selectedInstagramProfile.is_verified && (
                    <MdVerified className="top-bar__title__verified-icon text-[#00a0ff]" />
                  )}
                </div>
                <span className="!text-gray-500 transition-colors duration-200 cursor-pointer top-bar__menu-btn hover:!text-gray-700"
                  onClick={() =>
                    window.open(`https://www.instagram.com/${selectedInstagramProfile.username}`)
                  }>
                  {isTinderCard &&
                    (isLoading ? (
                      <LoadingSpinner className='w-5 h-5' />
                    ) : (
                      <TbExternalLink className="w-5 h-5" />
                    ))}
                </span>
              </section>
              <section className="stats">
                <ImageWithFallback className="stats__img-holder w-[90px] h-[90px] rounded-full !border-0"
                  src={selectedInstagramProfile.profile_pic_url} alt={selectedInstagramProfile.username}
                  width={90}
                  height={90}
                  placeholder="blur"
                  blurDataURL="/img/default_avatar.jpeg"
                  fallback={"/img/default_avatar.jpeg"}
                  unoptimized={isTinderCard}
                />

                <div className="stats__data">
                  <div className="stats__data__point">
                    <div className="stats__data__point__value ">
                      {selectedInstagramProfile.media_count ? selectedInstagramProfile.media_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '...'}
                    </div>
                    <div className="stats__data__point__description ">
                      Posts
                    </div>
                  </div>
                  <div className="stats__data__point">
                    <div className="stats__data__point__value ">
                      {instagramProfile ?
                        <span className={clsx('mx-auto h-8 flex transform-gpu items-center justify-center rounded-lg text-center transition-all duration-1000',
                          showAfterFollowers
                            ? 'mb-1 bg-green-500 py-1 px-2 text-white saturate-[1.2]'
                            : '')}>
                          {selectedInstagramProfile.follower_count ? (parseInt(showAfterFollowers ? selectedInstagramProfile.follower_count * 1.9 : selectedInstagramProfile.follower_count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')) : '...'}
                        </span>
                        : <CountUpHook />}
                    </div>
                    <div className="stats__data__point__description ">
                      Followers
                    </div>
                  </div>
                  <div className="stats__data__point">
                    <div className="stats__data__point__value ">
                      {selectedInstagramProfile.following_count ? selectedInstagramProfile.following_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '...'}
                    </div>
                    <div className="stats__data__point__description ">
                      Following
                    </div>
                  </div>
                </div>{' '}
              </section>
              <section className="description">
                <span className="decription__title">
                  {selectedInstagramProfile.full_name}
                </span>
                <p className="decription__text">
                  {selectedInstagramProfile.bio && selectedInstagramProfile.bio.length > 0 && (selectedInstagramProfile.bio)}
                </p>
                {selectedInstagramProfile.external_url &&
                  selectedInstagramProfile.external_url.length > 0 && (
                    <button className='text-left' aria-label={selectedInstagramProfile.external_url.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')}
                      onClick={() => window.open(selectedInstagramProfile.external_url)}>
                      {selectedInstagramProfile.external_url.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')}
                    </button>
                  )}
              </section>
              <section className={`actions ${isOnInstagramProfilePage ? '!grid-cols-1' : ''}`}>
                {/* <Anchor href={`/sign-up?ref=ig_preview${selectedInstagramProfile.username != demoInstagramProfile.username ? `&username=${selectedInstagramProfile.username}` : ''}`} */}

                {isTinderCard ? (
                  <Anchor href={`https://www.instagram.com/${selectedInstagramProfile.username}`} target="__blank" className="text-center actions__btn actions__btn--active">
                    Follow
                  </Anchor>
                ) : (
                  <Anchor href={`/setup${selectedInstagramProfile.username != demoInstagramProfile.username ? `?username=${selectedInstagramProfile.username}` : ''}`}
                    target="__blank" className="text-center actions__btn actions__btn--active">
                    {/* {selectedInstagramProfile.username != demoInstagramProfile.username && !isSampleAccount ? 'Start Growth' : 'Follow'} */}
                    Start Growth
                  </Anchor>
                )}

                {!isOnInstagramProfilePage && (
                  <>
                    <Anchor href={(selectedInstagramProfile.username != demoInstagramProfile.username && !isSampleAccount && !isTinderCard) ? `/instagram/${selectedInstagramProfile.username}?ref=ig_preview` : '#'}
                      className="actions__btn " style={{ background: 'white' }}>
                      {(selectedInstagramProfile.username != demoInstagramProfile.username && !isSampleAccount && !isTinderCard) ? 'Preview Growth' : 'Message'}
                    </Anchor>
                    <span className="relative w-full bg-white actions__btn actions__btn--icon">
                      <HiChevronDown className="my-auto w-full text-[17px]" />
                      {/* <em className="icon ni ni-chevron-down" style={{ fontSize: "19px", marginLeft: "1px", marginTop: "4px" }} /> */}
                    </span>
                  </>
                )}
              </section>
              <section className="tabs">
                <span className="tabs__btn tabs__btn--active">
                  <Image
                    width={22}
                    height={22}
                    className="tabs__btn__icon"
                    src="/img/icons/grid-icon.png"
                    alt="grid icon from Instagram app"
                    loading='eager'
                    quality={70}
                  />
                </span>
                <span className="tabs__btn">
                  <Image
                    width={22}
                    height={22}
                    className="tabs__btn__icon"
                    src="/img/icons/igtv-icon.png"
                    alt="igtv icon from Instagram app"
                    loading='eager'
                    quality={70}
                  />
                </span>
                <span className="tabs__btn">
                  <Image
                    width={22}
                    height={22}
                    className="tabs__btn__icon"
                    src="/img/icons/smile-icon.png"
                    alt="smile icon from Instagram app"
                    loading='eager'
                    quality={70}
                  />
                </span>
                <span className="tabs__btn">
                  <Image
                    width={22}
                    height={22}
                    className="tabs__btn__icon"
                    src="/img/icons/tagged-icon.png"
                    alt="tagged icon from Instagram app"
                    loading='eager'
                    quality={70}
                  />
                </span>
              </section>

              {/* IG Posts grid */}
              {isTinderCard && selectedInstagramProfile && !selectedInstagramProfile.posts
                ? (
                  <section className="grid overflow-x-scroll max-h-[310px]">
                    {' '}
                    {Array(9).fill(0).map((post, index) => (
                      <a href="#" key={index} className="grid__photo transform-gpu animate-pulse !bg-gray-300" style={{ width: '133px', height: '124px' }} />
                    ))}
                  </section>
                )
                : selectedInstagramProfile && selectedInstagramProfile.posts && selectedInstagramProfile.posts.length > 0 && (
                  <section className="grid overflow-x-scroll max-h-[310px]">
                    {' '}
                    {selectedInstagramProfile.posts.map((post: InstagramPostProps, index) => (
                      <Anchor className="overflow-hidden grid__photo"
                        href={`https://www.instagram.com/p/${post.id}`}
                        key={index}
                        target="__blank"
                        title={`@${selectedInstagramProfile.username}'s Instagram post posted on date ${new Date(post.taken_at * 1000).toLocaleDateString()} which generated ${post.like_count} likes and ${post.comment_count} comments`}
                        style={{
                          width: '133px',
                          height: '124px',
                        }}
                      >
                        <ImageWithFallback
                          width={133}
                          height={124}
                          src={Instagram.decodeInstagramMediaURL(post.image_url)}
                          alt={`@${selectedInstagramProfile.username}'s Instagram post posted on date ${new Date(post.taken_at * 1000).toLocaleDateString()} which generated ${post.like_count} likes and ${post.comment_count} comments`}
                          loading='lazy'
                          className="!object-cover !object-center !rounded-md !bg-gray-300 !h-full"
                          fallback={"/img/placeholder/instagram-post-placeholder.jpeg"}
                          placeholder="blur"
                          blurDataURL="/img/placeholder/instagram-post-placeholder.jpeg"
                        />
                      </Anchor>
                    ))}
                  </section>
                )}
            </>
          )
        )}
      </div>
    </div>
  );
}

function SkeletonInstagramProfile() {
  return (
    <div className="relative pb-0">
      <section className="top-bar !justify-center">
        <div className="top-bar__title">
          {' '}
          <span className="top-bar__title__text !w-24 h-6 !mt-1.5 !mb-0.5 transform-gpu animate-pulse rounded-md bg-gray-300" />
        </div>
      </section>
      <section className="stats">
        <div className="bg-gray-300 stats__img-holder transform-gpu animate-pulse" />
        <div className="stats__data">
          <div className="stats__data__point">
            <div className="h-6 mb-1 bg-gray-300 rounded-md stats__data__point__value w-14 transform-gpu animate-pulse" />
            <div className="stats__data__point__description ">Posts</div>
          </div>
          <div className="stats__data__point">
            <div className="h-6 mb-1 bg-gray-300 rounded-md stats__data__point__value w-14 transform-gpu animate-pulse" />
            <div className="stats__data__point__description ">Followers</div>
          </div>
          <div className="stats__data__point">
            <div className="h-6 mb-1 bg-gray-300 rounded-md stats__data__point__value w-14 transform-gpu animate-pulse" />
            <div className="stats__data__point__description ">Following</div>
          </div>
        </div>{' '}
      </section>

      <section className="description">
        <span className="decription__title h-6 w-[100px] transform-gpu animate-pulse rounded-md bg-gray-300"></span>
        <p className="w-full h-6 bg-gray-300 rounded-md decription__text transform-gpu animate-pulse"></p>
        <span className="h-6 mt-2 bg-gray-300 rounded-md w-14 transform-gpu animate-pulse"></span>
      </section>

      <section className="actions">
        <span className="actions__btn actions__btn--active transform-gpu animate-pulse" />
        <span className="bg-gray-300 actions__btn transform-gpu animate-pulse" />
        <span className="relative w-full bg-gray-300 actions__btn actions__btn--icon transform-gpu animate-pulse" />
      </section>
      <section className="tabs">
        <span className="tabs__btn tabs__btn--active">
          <Image
            width={22}
            height={22}
            className="tabs__btn__icon"
            src="/img/icons/grid-icon.png"
            alt="grid icon from Instagram app"
            loading="eager"
            quality={70}
          />
        </span>
        <span className="tabs__btn">
          <Image
            width={22}
            height={22}
            className="tabs__btn__icon"
            src="/img/icons/igtv-icon.png"
            alt="igtv icon from Instagram app"
            loading="eager"
            quality={70}
          />
        </span>
        <span className="tabs__btn">
          <Image
            width={22}
            height={22}
            className="tabs__btn__icon"
            src="/img/icons/smile-icon.png"
            alt="smile icon from Instagram app"
            loading="eager"
            quality={70}
          />
        </span>
        <span className="tabs__btn">
          <Image
            width={22}
            height={22}
            className="tabs__btn__icon"
            src="/img/icons/tagged-icon.png"
            alt="tagged icon from Instagram app"
            loading="eager"
            quality={70}
          />
        </span>
      </section>
      {/* Reenabled to add posts grid */}
      {/* {includePosts && (
        <section className="grid ">
          {' '}
          {Array(6)
            .fill(0)
            .map((post, index) => (
              <a
                href="#"
                key={index}
                className="grid__photo transform-gpu animate-pulse !bg-gray-300"
              />
            ))}
        </section>
      )} */}
    </div>
  );
}

